export default {
  "container": "qVp",
  "header": "qVx",
  "title": "qVw caption-bold",
  "search-icon": "qVN",
  "text": "qVv body-2",
  "trigger": "qVo",
  "loading-state": "qVn",
  "dropzone-placeholder-wrapper": "qVi",
  "dropzone-placeholder": "qVA",
  "file-loading": "qVY"
};
