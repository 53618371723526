export default {
  "vat-lines": "qwY",
  "vat-line": "qwh",
  "vat-input": "qwf",
  "vat-rate": "qwK",
  "vat-option-button": "qwG",
  "disabled": "qwr",
  "vat-option-tooltip": "qwb",
  "remove-label": "qwy",
  "remove-button": "qwU",
  "vat-total": "qwj",
  "vat-total-amount": "qwW",
  "add-vat-line": "qwu",
  "with-vat-lines": "qNS"
};
