export default {
  "container": "qVI",
  "item": "qVt caption-bold",
  "hover": "qVM",
  "active": "qVP",
  "name-wrapper": "qVl",
  "name": "qVX body-2",
  "count": "qVC",
  "actions-placeholder": "qVk",
  "actions": "qVJ",
  "show": "qVs",
  "action-button": "qVF"
};
