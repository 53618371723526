export default {
  "header-cell": "qOV",
  "empty": "qOL",
  "header-type": "qOT",
  "header-requester": "qOz",
  "header-amount": "qOa",
  "header-approval": "qOH",
  "header-content": "qOO caption-bold",
  "active": "qOm",
  "row-compact": "qOp",
  "row-sidebar": "qOx",
  "hidden": "qOw",
  "animated": "qON",
  "fadein-item": "qOv",
  "animated-cell": "qOo"
};
