export default {
  "row": "qOn",
  "cell": "qOi",
  "empty": "qOA",
  "icon": "qOY",
  "type": "qOh",
  "amount-content": "qOf",
  "cell-approve": "qOK",
  "approve-content": "qOG",
  "row-compact": "qOr"
};
