export default {
  "header-cell": "qTz",
  "header-content": "qTa caption-bold",
  "active": "qTH",
  "align-right": "qTO",
  "empty": "qTm",
  "align-checkbox": "qTp",
  "col-5": "qTx",
  "col-9": "qTw",
  "emitted-at-header-tag": "qTN"
};
