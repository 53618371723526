export default {
  "row": "qmh body-1",
  "active": "qmf",
  "cell": "qmK",
  "empty": "qmG",
  "ellipsis": "qmr",
  "text-secondary": "qmb",
  "cell-type": "qmy",
  "cell-reason": "qmU",
  "cell-amount": "qmj",
  "row-sidebar": "qmW",
  "animated": "qmu",
  "fadein-item": "qpS",
  "animated-cell": "qpc"
};
