export default {
  "wrapper": "qzO",
  "title": "qzm",
  "activity-tag": "qzp",
  "select": "qzx",
  "tooltip": "qzw",
  "date-picker": "qzN",
  "internal-notes": "qzv",
  "scheduled": "qzo"
};
