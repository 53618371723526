export default {
  "header-cell": "qmD",
  "empty": "qmV",
  "header-content": "qmL caption-bold",
  "active": "qmT",
  "header-type": "qmz",
  "header-reason": "qma",
  "header-status": "qmH",
  "header-amount": "qmO",
  "row-sidebar": "qmm",
  "hidden": "qmp",
  "animated": "qmx",
  "fadein-item": "qmw",
  "animated-cell": "qmN"
};
