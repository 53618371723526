export default {
  "button-wrapper": "qDf",
  "btn-filter": "qDK",
  "name-wrapper": "qDG",
  "preset-name": "qDr",
  "cancel-placeholder": "qDb",
  "cancel-button": "qDy",
  "dropdown-opened": "qDU",
  "dropdown-closed": "qDj",
  "dropdown-menu": "qDW",
  "loading-item": "qDu",
  "placeholder": "qVS",
  "list-body": "qVc",
  "list-item": "qVq",
  "error-state": "qVZ body-2",
  "warning": "qVR",
  "warning-icon": "qVQ",
  "footer": "qVe",
  "add-icon": "qVB",
  "empty-state": "qVE"
};
