export default {
  "row": "qxi",
  "counterparty-name": "qxA",
  "counterparty-status": "qxY",
  "amount": "qxh",
  "next-date": "qxf",
  "end-date": "qxK",
  "frequency": "qxG",
  "active": "qxr",
  "cell": "qxb body-2",
  "no-padding": "qxy",
  "cell-content": "qxU",
  "counterparty": "qxj",
  "status-avatar": "qxW",
  "beneficiary-name": "qxu",
  "empty": "qwS",
  "disabled": "qwc",
  "fadein-item": "qwq"
};
