export default {
  "card-header": "qLd",
  "counterparty-name": "qLI",
  "pan-section": "qLt header-section body-2",
  "infobox": "qLM header-section",
  "see-card-button": "qLP",
  "card-identifier-section": "qLl",
  "card-icon": "qLX",
  "nickname": "qLC body-1",
  "pan": "qLk body-2"
};
