/* import __COLOCATED_TEMPLATE__ from './toggle-basic.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { Badge, Disclaimer } from '@repo/design-system-kit';

export default class TransfersSepaPayLaterToggleBasicComponent extends Component {
  @service segment;

  disclaimer = Disclaimer.Inline;
  badge = Badge;

  constructor() {
    super(...arguments);

    if (this.args.origin) {
      this.segment.track('pay-later_toggle_displayed', {
        origin: this.args.origin,
      });
    }
  }
}
