export default {
  "header-cell": "qxa",
  "align-right": "qxH",
  "header-content": "qxO caption-bold",
  "active": "qxm",
  "empty": "qxp",
  "col-12": "qxx",
  "col-8": "qxw",
  "col-6": "qxN",
  "col-4": "qxv",
  "col-2": "qxo",
  "hide": "qxn"
};
