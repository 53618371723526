export default {
  "modal": "qgc",
  "section": "qgq",
  "header": "qgZ",
  "title": "qgR",
  "separator-title": "qgQ",
  "icon": "qge",
  "formats": "qgB",
  "columns": "qgE",
  "column-list": "qgd",
  "draggable-column": "qgI",
  "column-field": "qgt",
  "column-selector": "qgM",
  "column-with-error": "qgP",
  "handle": "qgl",
  "label-color": "qgX",
  "disclaimer": "qgC"
};
