/* import __COLOCATED_TEMPLATE__ from './invoice-details.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { Spinner } from '@repo/design-system-kit';
import dayjs from 'dayjs';
import { all, dropTask, race, rawTimeout, restartableTask, task, timeout } from 'ember-concurrency';

import { SUPPLIER_INVOICE_EVENTS } from 'qonto/constants/listeners';
import { DEBOUNCE_MS } from 'qonto/constants/timers';
import {
  PAY_BY_INVOICE_ANIMATION_IN_DURATION_MS,
  PAY_BY_INVOICE_WAIT_FOR_OCR_SCAN,
} from 'qonto/constants/transfers';
import { isIbanFromSepaZone } from 'qonto/utils/beneficiaries';
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';
import {
  copyBeneficiaryIntoTransfer,
  copyBeneficiaryLabelsIntoTransfer,
  copyBeneficiaryVatIntoTransfer,
} from 'qonto/utils/transfers';

export default class TransfersSepaInvoiceDetailsComponent extends Component {
  spinner = Spinner;

  @service abilities;
  @service beneficiariesManager;
  @service errors;
  @service intl;
  @service modals;
  @service notifierManager;
  @service organizationManager;
  @service supplierInvoicesManager;
  @service segment;
  @service sentry;
  @service toastFlashMessages;

  @tracked beneficiaries = [];

  invoiceAnalysisResult = {
    amount_read: false,
    beneficiary_IBAN_read: false,
    beneficiary_IBAN_matched: false,
    beneficiary_name_read: false,
    beneficiary_name_matched: false,
    invoice_number_read: false,
  };

  constructor() {
    super(...arguments);
    this.initiateTransferFromInvoiceTask
      .perform()
      .catch(ignoreCancelation)
      .catch(error => {
        if (ErrorInfo.for(error).shouldSendToSentry) this.sentry.captureException(error);
        this.toastFlashMessages.toastError(
          this.errors.messageForStatus(error) || this.intl.t('toasts.errors.server_error')
        );
      });
  }

  get beneficiary() {
    return this.args.context.sepaTransfer.get('beneficiary').content;
  }

  get beneficiaryErrorMessage() {
    if (this.hasMissingBeneficiaryError) {
      if (!this.loadBeneficiariesTask.lastSuccessful.value.length) {
        return this.intl.t('transfers.sepa.invoice.add-beneficiary-error');
      }

      return this.intl.t('validations.errors.transfer.presence');
    }

    return null;
  }

  get hasMissingBeneficiaryError() {
    return this.args.isValidationEnabled && !this.beneficiary;
  }

  get sepaTransfer() {
    return this.args.context.sepaTransfer;
  }

  get invoiceLifeTime() {
    return Math.max(dayjs().diff(this.args.context.invoice.createdAt), 0) || 0;
  }

  get shouldShowOcrAnimation() {
    return this.waitForOcrScanTask.isRunning;
  }

  get shouldTrackOcrResult() {
    return this.waitForOcrScanTask.performCount === 1;
  }

  initiateTransferFromInvoiceTask = task(async () => {
    let tasks = [this.loadBeneficiariesTask.perform()];

    let { invoice } = this.args.context;
    let { analyzedAt } = invoice;

    if (!analyzedAt && this.invoiceLifeTime <= PAY_BY_INVOICE_WAIT_FOR_OCR_SCAN) {
      tasks.push(this.waitForOcrScanTask.perform());
    } else {
      tasks.push(this.prefillTransferTask.perform(invoice));
    }

    await all(tasks);

    if (this.shouldTrackOcrResult) {
      let { origin } = this.args.context;
      this.segment.track('transfer-sepa_transfer_details_displayed', {
        flow: 'byinvoice',
        ...(origin && { origin }),
        ...this.invoiceAnalysisResult,
      });
    }
  });

  loadBeneficiariesTask = dropTask(async () => {
    try {
      let allBeneficiaries = await this._loadBeneficiaries();
      this.beneficiaries = allBeneficiaries;
      return allBeneficiaries;
    } catch {
      this.beneficiaries = [];
      return [];
    }
  });

  prefillTransferTask = dropTask(async invoice => {
    await invoice.reload();

    let { beneficiary, invoiceAnalysisResult, organizationManager, sepaTransfer } = this;
    let { iban, invoiceNumber, supplierName, totalAmount } = invoice;

    invoiceAnalysisResult.beneficiary_name_read = Boolean(supplierName);
    invoiceAnalysisResult.beneficiary_IBAN_read = Boolean(iban);
    invoiceAnalysisResult.amount_read = Boolean(totalAmount?.value);
    invoiceAnalysisResult.invoice_number_read = Boolean(invoiceNumber);

    sepaTransfer.setProperties({
      amount: sepaTransfer.amount || (totalAmount?.value ?? 0),
      reference: sepaTransfer.reference || (invoiceNumber ?? ''),
    });

    let isBeneficiaryPrefilled = null;
    if (!beneficiary && iban) {
      let matchingBeneficiary = await this.beneficiariesManager.getSEPABeneficiaryByIban(
        organizationManager.organization.id,
        iban
      );
      if (matchingBeneficiary) {
        invoiceAnalysisResult.beneficiary_IBAN_matched = true;
        invoiceAnalysisResult.beneficiary_name_matched = matchingBeneficiary.name === supplierName;

        if (isIbanFromSepaZone(matchingBeneficiary.iban)) {
          this._selectBeneficiary(matchingBeneficiary);
          isBeneficiaryPrefilled = true;
        }
      }
    }

    let hasOCRSucceded = [
      isBeneficiaryPrefilled,
      invoiceAnalysisResult.amount_read,
      invoiceAnalysisResult.invoice_number_read,
    ].some(Boolean);
    if (!hasOCRSucceded) {
      this.toastFlashMessages.toastError(this.intl.t('errors.invoice_ocr_failed'));
    }
  });

  searchBeneficiaryTask = restartableTask(async searchQuery => {
    try {
      await timeout(DEBOUNCE_MS);
      let filteredBeneficiaries = await this._loadBeneficiaries(searchQuery);
      this.beneficiaries = filteredBeneficiaries;
      return filteredBeneficiaries;
    } catch {
      this.beneficiaries = [];
      return [];
    }
  });

  selectBeneficiary = dropTask(async beneficiary => {
    if (!isIbanFromSepaZone(beneficiary.iban)) {
      this.segment.track('transfer-sepa_out_of_SEPA_modal_displayed');
      return await this.modals.open('transfers/sepa/not-sepa-modal', {
        onCancel: () => this.segment.track('transfer-sepa_out_of_SEPA_cancel_clicked'),
        onConfirm: () => {
          this.segment.track('transfer-sepa_out_of_SEPA_edit_clicked');
          this.editBeneficiary(beneficiary);
        },
      });
    }

    this._selectBeneficiary(beneficiary);
  });

  waitForOcrScanTask = dropTask(async () => {
    let { invoice, origin } = this.args.context;

    this.segment.track('transfer-sepa_OCR_transition_displayed', {
      ...(origin && { origin }),
    });

    let waitForOcrAnimation = rawTimeout(PAY_BY_INVOICE_ANIMATION_IN_DURATION_MS);
    let waitForScanCompletionOrDelay = this.waitForScanCompletionOrDelayTask.perform();

    let [eventedResponse] = await all([waitForScanCompletionOrDelay, waitForOcrAnimation]);

    if (eventedResponse?.event === SUPPLIER_INVOICE_EVENTS.ANALYZED) {
      let { payload } = eventedResponse;
      let id = this.supplierInvoicesManager.getSupplierInvoiceIdFromAnalyzedEvent(payload);

      if (id === invoice.id) {
        await this.prefillTransferTask.perform(invoice);
      }
    } else {
      // If we timed out waiting for the websocket, we try to refetch and prefill last time
      await this.prefillTransferTask.perform(invoice);
    }
  });

  waitForScanCompletionOrDelayTask = task(async () => {
    let isInvoiceScanned = this.notifierManager.waitForEventTask.perform(
      SUPPLIER_INVOICE_EVENTS.ANALYZED
    );
    let delay = rawTimeout(PAY_BY_INVOICE_WAIT_FOR_OCR_SCAN);
    return await race([isInvoiceScanned, delay]);
  });

  @action
  addBeneficiary() {
    let { context, pushFlow } = this.args;
    let { origin } = context;
    this.segment.track('transfer-sepa_new-beneficiary_clicked', {
      flow: 'byinvoice',
      ...(origin && { origin }),
    });
    // We reset the `beneficiaryToEdit` property in case we have already visited the beneficiary edition flow
    context.beneficiaryToEdit = null;
    pushFlow('sepa-transfer-beneficiary', 'add-beneficiary');
  }

  @action
  editBeneficiary(beneficiary) {
    let { context, pushFlow } = this.args;
    let { origin } = context;
    this.segment.track('transfer-sepa_edit-beneficiary_clicked', {
      flow: 'byinvoice',
      ...(origin && { origin }),
    });
    context.beneficiaryToEdit = beneficiary;
    pushFlow('sepa-transfer-beneficiary', 'edit-beneficiary');
  }

  async _loadBeneficiaries(searchQuery) {
    return await this.beneficiariesManager.loadSepaBeneficiaries(
      this.organizationManager.organization.id,
      searchQuery
    );
  }

  _selectBeneficiary(beneficiary) {
    this.sepaTransfer.set('beneficiary', beneficiary);

    copyBeneficiaryIntoTransfer(this.sepaTransfer, beneficiary, { forceCopy: true });

    copyBeneficiaryLabelsIntoTransfer(this.sepaTransfer, beneficiary);

    if (this.abilities.can('view vat bookkeeping')) {
      copyBeneficiaryVatIntoTransfer(this.sepaTransfer, beneficiary);
    }

    let { origin } = this.args.context;
    this.segment.track('transfer-sepa_beneficiary_selected', {
      flow: 'byinvoice',
      ...(origin && { origin }),
    });
  }
}
