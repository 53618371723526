export default {
  "transfer-form": "qHF",
  "transfer-form-title": "qHg",
  "transfer-form-subtitle": "qHD",
  "form": "qHV",
  "form-footer": "qHL",
  "footer-btn": "qHT",
  "header": "qHz",
  "main-settings": "qHa",
  "additional-settings": "qHH",
  "form-container": "qHO"
};
