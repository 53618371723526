export default {
  "row": "qwZ",
  "col": "qwR",
  "counterparty": "qwQ",
  "header": "qwe",
  "icon": "qwB",
  "col-12": "qwE",
  "col-8": "qwd",
  "col-6": "qwI",
  "col-4": "qwt",
  "col-2": "qwM",
  "hide": "qwP"
};
