export default {
  "custom-export-loading": "qgy",
  "title": "qgU",
  "description": "qgj",
  "section": "qgW",
  "placeholder-avatar": "qgu",
  "_with-avatar": "qDS",
  "_with-list": "qDc",
  "list-item": "qDq",
  "radio": "qDZ",
  "header": "qDR",
  "header-line": "qDQ"
};
