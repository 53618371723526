export default {
  "row": "qTv",
  "item-bulk": "qTo",
  "active": "qTn",
  "disabled": "qTi",
  "cell": "qTA body-2",
  "not-disabled": "qTY",
  "item-amount-disabled": "qTh",
  "item-amount-credit": "qTf",
  "no-padding": "qTK",
  "cell-content": "qTG",
  "centered-cell": "qTr",
  "item-counterparty-icon": "qTb mr-16",
  "avatar": "qTy",
  "item-counterparty-name": "qTU",
  "item-counterparty-name-status": "qTj",
  "disputing-chargeback": "qTW",
  "item-counterparty-name-text": "qTu",
  "item-amount": "qzS body-1",
  "item-amount-foreign": "qzc",
  "attachment-icon": "qzq",
  "item-method": "qzZ",
  "bank-account-avatar": "qzR",
  "attachment-status-dot": "qzQ",
  "attached": "qze",
  "missing": "qzB",
  "item-attachment": "qzE",
  "fadein-item": "qzd"
};
