export default {
  "wrapper": "qFn",
  "section-wrapper": "qFi",
  "header": "qFA",
  "title": "qFY body-1",
  "close-icon": "qFh",
  "section": "qFf",
  "section-title": "qFK caption-bold",
  "labels": "qFG",
  "labels-title": "qFr",
  "section-checkbox": "qFb",
  "grow": "qFy scroll",
  "label": "qFU",
  "label-spacing": "qFj",
  "buttons": "qFW"
};
