export default {
  "filters-checkbox": "qDF mb-8",
  "csv-options-button": "qDg",
  "active": "qDD",
  "options-format-settings-wrapper": "qDV",
  "checkbox": "qDL",
  "options-format-settings": "qDT",
  "hidden": "qDz",
  "visible": "qDa",
  "disabled": "qDH",
  "buttons": "qDO"
};
