export default {
  "transaction": "qDw",
  "transaction-details": "qDN",
  "transaction-details-amount": "qDv body-1",
  "credit": "qDo",
  "negative-amount": "qDn",
  "transaction-details-description": "qDi body-2",
  "transaction-remove-cta": "qDA",
  "avatar": "qDY mr-16",
  "spinner": "qDh"
};
