/* import __COLOCATED_TEMPLATE__ from './card.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { IN_DISPUTE_STATUS } from 'qonto/constants/transactions';
import { Counterparty } from 'qonto/react/components/transactions/sidebar/header/counterparty';
import { Date } from 'qonto/react/components/transactions/sidebar/header/date';
import { Type } from 'qonto/react/components/transactions/sidebar/header/type';

export default class TransactionsSidebarHeaderCard extends Component {
  Counterparty = Counterparty;
  Date = Date;
  type = Type;

  @service abilities;
  @service router;
  @service segment;

  get showLocalAmount() {
    let { highlightedItem: transaction } = this.args;

    return (
      transaction.localAmount > 0 && transaction.amountCurrency !== transaction.localAmountCurrency
    );
  }

  get headerStatus() {
    let { highlightedItem: transaction } = this.args;

    // 'disputing' is not a real transaction status, but it's used
    // to show that a Chargeback claim is in progress for that transaction
    if (transaction.chargebackDisputingTypes?.length > 0) {
      return IN_DISPUTE_STATUS;
    }

    return transaction.status;
  }

  get cardImageUri() {
    let cardDesign = this.args.highlightedItem.subject.get('design');
    if (cardDesign) {
      return `/illustrations/cards/card-${cardDesign}.svg`;
    }
    return null;
  }

  @action
  onClickCardPreview() {
    // Using aria-disabled doesn't prevent click event from being triggered
    if (this.abilities.cannot('read card')) return;

    this.segment.track('transaction_panel_card_link_clicked');
    this.router.transitionTo('cards.index', {
      queryParams: {
        highlight: this.args.highlightedItem.subject.get('id'),
      },
    });
  }
}
