export default {
  "row": "qzI",
  "col": "qzt",
  "header": "qzM",
  "block": "qzP",
  "col-5": "qzl",
  "col-6": "qzX",
  "col-9": "qzC",
  "col-12": "qzk",
  "hide": "qzJ"
};
