export default {
  "sidebar-content": "qzf",
  "section": "qzK",
  "header-wrapper": "qzG",
  "transfer-id": "qzr caption",
  "small-padding": "qzb",
  "title": "qzy",
  "subtitle": "qzU",
  "actions": "qzj"
};
