export default {
  "beneficiary-details": "qpq",
  "beneficiary-account-types": "qpZ",
  "divider": "qpR",
  "label": "qpQ",
  "labels": "qpe",
  "error": "qpB",
  "link": "qpE caption",
  "sticky-panel": "qpd"
};
