export default {
  "row": "qOb body-1",
  "active": "qOy",
  "cell": "qOU",
  "empty": "qOj",
  "ellipsis": "qOW",
  "text-secondary": "qOu",
  "transfer-type": "qmS",
  "transfer-type__icon": "qmc",
  "quick-actions": "qmq",
  "amount": "qmZ",
  "cell-approve": "qmR",
  "cell-type": "qmQ",
  "cell-requester": "qme",
  "account-select": "qmB",
  "row-compact": "qmE",
  "row-sidebar": "qmd",
  "cell-amount": "qmI",
  "hidden": "qmt",
  "animated": "qmM",
  "fadein-item": "qmP",
  "animated-cell": "qml"
};
