export default {
  "beneficiary-details": "qaU",
  "beneficiary-country-disclaimer": "qaj",
  "beneficiary-account-types": "qaW",
  "divider": "qau",
  "error-disclaimer": "qHS",
  "label": "qHc",
  "edition-info-disclaimer": "qHq",
  "sticky-panel": "qHZ",
  "spinner": "qHR"
};
