/* import __COLOCATED_TEMPLATE__ from './bulk-action.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { Disclaimer } from '@repo/design-system-kit';
import { dropTask } from 'ember-concurrency';
import { modifier } from 'ember-modifier';

import { OPERATION_TYPES } from 'qonto/constants/transactions';
import { CashflowCategoryManager } from 'qonto/react/components/transactions/sidebar/category/cashflow-category';
import { ErrorInfo } from 'qonto/utils/error-info';
import filterActivityTagCodes from 'qonto/utils/filter-activity-tag-codes';

export default class TransactionsBulkActionComponent extends Component {
  disclaimerInline = Disclaimer.Inline;

  @service abilities;
  @service organizationManager;
  @service segment;
  @service store;
  @service toastFlashMessages;
  @service intl;
  @service sentry;
  @service categorizationRulesManager;

  @tracked _isQualified;
  @tracked _isMixedQualifiedStatus;
  @tracked hasChangeAttachmentStatus;
  @tracked hideCategorizationDisclaimer = true;
  @tracked cashflowCategoryCallback;
  @tracked cashflowCategoryId;

  CashflowCategoryManager = CashflowCategoryManager;

  get hasCategoryTags() {
    return filterActivityTagCodes(this.transactionsTypes).length > 0;
  }

  get canSubmitBulkEdit() {
    return (
      this.cashflowCategoryId !== undefined ||
      this.hasChangeAttachmentStatus ||
      this._isQualified !== undefined ||
      this.args.updatedCategory ||
      this.args.isAttachmentRequested ||
      this.args.updatedLabels
    );
  }

  @action
  handleBulkCategoryChange({ categoryId, callback }) {
    this.cashflowCategoryId = categoryId;
    this.cashflowCategoryCallback = callback;
  }

  get isSameCategory() {
    let firstCategory = this.args.transactions[0].activityTag;
    return this.args.transactions.every(item => item.activityTag === firstCategory);
  }

  get isQualified() {
    if (this._isQualified !== undefined) {
      return this._isQualified;
    }
    return this.args.transactions.every(item => item.qualifiedForAccounting);
  }

  get isMixedQualifiedStatus() {
    if (this._isMixedQualifiedStatus !== undefined) {
      return false;
    }
    return this.args.transactions.some(item => item.qualifiedForAccounting) && !this.isQualified;
  }

  get showActions() {
    return this.args.transactions.some(
      ({ isPayLater, credit, isBiller }) => !isBiller && !(isPayLater && credit)
    );
  }

  get showRequestAttachment() {
    return this.args.transactions.some(({ attachmentCount }) => !attachmentCount);
  }

  get showAnalyticLabels() {
    return this.abilities.can('access custom-label') && this.abilities.can('read custom-label');
  }

  get disabledRequestAttachment() {
    let { id: currentMembershipId } = this.organizationManager.membership;

    return this.args.transactions.every(({ initiatorId }) => {
      return !initiatorId || initiatorId === currentMembershipId;
    });
  }

  get hasAttachmentRequested() {
    return this.args.transactions.some(transaction => {
      return transaction.attachmentRequested;
    });
  }

  get transactionsTypes() {
    return this.args.transactions.reduce((acc, transaction) => {
      let { subjectType } = transaction;

      if (!acc.includes(subjectType)) {
        acc.push(subjectType);
      }

      return acc;
    }, []);
  }

  get trackingParams() {
    let params = {
      category: this.args.updatedCategory,
      labels: Boolean(this.args.updatedLabels?.length),
      request_receipts: this.args.isAttachmentRequested,
      mark_as_verified: this.isQualified,
      attachment_status: this.lost ? 'lost' : this.required ? 'required' : 'not required',
    };

    if (this.cashflowCategoryId !== undefined) {
      params.cashflow_category = this.cashflowCategoryId;
    }
    return params;
  }

  get updatableTransactions() {
    // Filter out transactions that have counted attachments
    let transactionsToUpdate = this.args.transactions.filter(
      transaction => !transaction.attachmentCount
    );

    // Filter out transaction that have operationType "biller"
    transactionsToUpdate = transactionsToUpdate.filter(
      transaction => transaction.operationType !== OPERATION_TYPES.BILLER
    );

    // If the user cannot update the status of attachments, filter out "not_required" transactions
    if (this.abilities.cannot('update attachment required status transaction')) {
      transactionsToUpdate = transactionsToUpdate.filter(
        transaction =>
          !(transaction.attachmentRequired === false && transaction.attachmentLost === false)
      );
    }

    return transactionsToUpdate;
  }

  submitBulkEditTask = dropTask(async () => {
    this.segment.track('history_bulk_panel_apply_button_clicked', this.trackingParams);

    let transactionIds = this.args.transactions.map(item => item.get('id'));
    let hasUpdatedLabels = this.args?.updatedLabels?.length > 0;

    try {
      if (this._isQualified !== undefined) {
        await this.args.markAsReviewTask.perform(this.isQualified, transactionIds);
      }

      if (this.hasChangeAttachmentStatus) {
        let updatableTransactionsIds = this.updatableTransactions.map(item => item.get('id'));
        await this.updateAttachmentStatusesTask.perform(updatableTransactionsIds);

        transactionIds = updatableTransactionsIds;
      }

      if (this.cashflowCategoryId !== undefined) {
        await this.cashflowCategoryCallback();
        this.cashflowCategoryId = undefined;
        this.cashflowCategoryCallback = undefined;
      }

      await this.args.submitBulkEdit();

      if (hasUpdatedLabels) {
        this.hideCategorizationDisclaimer = false;
      }

      this.hasChangeQualifiedStatus = false;
      this.toastFlashMessages.toastInfo(
        this.intl.t('toasts.transactions_bulk_edit', {
          count: transactionIds.length,
        })
      );
      this._isQualified = undefined;
    } catch {
      await this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
    }
  });

  updateAttachmentStatusesTask = dropTask(async transactionIds => {
    let transaction = this.store.createRecord('transaction');
    let { id: organizationId } = this.organizationManager.organization;

    try {
      await transaction.updateAttachmentStatuses({
        required: this.required,
        lost: this.lost,
        transactionIds,
        organizationId,
      });
    } catch (error) {
      let errorInfo = ErrorInfo.for(error);
      // We want to ignore bad requests if no transaction ids are provided and
      // display the toast indicating that 0 transactions have been updated
      // This can happen if only transactions of different types are selected,
      // which are all non-updatable (such as those that already have an attachment and are of type "biller")
      if (errorInfo.httpStatus === 400 && !transactionIds.length) {
        return;
      }
      if (errorInfo.shouldSendToSentry) {
        this.sentry.captureException(error);
      }
    } finally {
      transaction.deleteRecord();
    }
  });

  _getListSelectedLabels(labelList) {
    return this.args.updatedLabels.filter(label => label.labelList.id === labelList.id);
  }

  /**
   * The purpose of this function is to tell if a label
   * belonging to the labelList is already in the
   * updatedLabels array
   *
   * @param {*} labelList List of labels to search in
   * @returns {Boolean}
   */
  _hasListSelectedLabel(labelList) {
    let labelFound = this._getListSelectedLabels(labelList);
    return Boolean(labelFound);
  }

  /**
   * Clears the currently selected label of a given label list
   *
   * @param {*} labelList List of labels
   * @returns {Boolean}
   */
  _clearListSelectedLabel(labelList) {
    let listSelectedLabels = this._getListSelectedLabels(labelList);
    listSelectedLabels.forEach(label => {
      let index = this.args.updatedLabels.indexOf(label);
      this.args.updatedLabels.splice(index, 1);
    });
  }

  @action
  updateAttachmentStatus(lost, required) {
    this.lost = lost;
    this.required = required;
    this.hasChangeAttachmentStatus = true;
  }

  @action
  handlePrepareSaveLabel(labelList, label) {
    let hasSelectedLabel = this._hasListSelectedLabel(labelList);

    if (hasSelectedLabel) {
      this.hideCategorizationDisclaimer = true;
    }

    if (this.args.updatedLabels.length && hasSelectedLabel) {
      this._clearListSelectedLabel(labelList);
    }

    this.segment.track('transaction_analytic_label_edited', {
      source: 'bulk_details',
    });

    this.args.updatedLabels.push(label);
  }

  @action
  changeQualifiedStatus() {
    let qualify = this.isMixedQualifiedStatus ? false : !this.isQualified;
    this._isMixedQualifiedStatus = false;
    this._isQualified = qualify;
  }

  @action
  onGenerateRules(rules) {
    this.categorizationRulesManager.onCreateAnalyticLabelRulesTask.perform(rules).catch(() => {
      this.args.discardBulkEdit();
      this.hideCategorizationDisclaimer = true;
    });
  }

  /* eslint-disable-next-line no-unused-vars */ // Removing the unused vars breaks the modifier
  transactionsChangedModifier = modifier((element, [transactions]) => {
    this.hideCategorizationDisclaimer = true;
  });
}
