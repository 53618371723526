export default {
  "fileupload": "qwH",
  "file": "qwO",
  "label": "qwm",
  "zone": "qwp",
  "error": "qwx",
  "hide": "qww",
  "dropzone": "qwN",
  "hidden": "qwv",
  "upload-icon": "qwo",
  "dropping": "qwn",
  "bounce2": "qwi",
  "error-message": "qwA"
};
