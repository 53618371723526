export default {
  "wrapper": "qac",
  "ember-power-select-dropdown": "qaq",
  "status": "qaZ body-1",
  "error": "qaR",
  "success": "qaQ",
  "type-container": "qae",
  "type-close": "qaB",
  "date": "qaE",
  "close-icon": "qad",
  "container": "qaI",
  "container-picto": "qat",
  "avatar": "qaM",
  "container-picto-status": "qaP",
  "general": "qal",
  "general-amount": "qaX",
  "unprocessed": "qaC",
  "fx-rate": "qak",
  "general-counterparty": "qaJ body-2",
  "infobox": "qas",
  "account-infobox": "qaF qas",
  "initiator-infobox": "qag qas",
  "transaction-infobox": "qaD qas",
  "repeat-infobox": "qaV qas",
  "item": "qaL body-2",
  "eye-icon": "qaT",
  "repeat-transfer-action": "qaz",
  "disclaimer": "qaa",
  "start-verification-link": "qaH",
  "financing-not-compliant-disclaimer": "qaO"
};
