export default {
  "transfer-header": "qTd",
  "infobox": "qTI header-section",
  "proof-section": "qTt",
  "proof-link": "qTM",
  "download-icon": "qTP",
  "cancel-button": "qTl",
  "request-refund-button": "qTX",
  "refund-requested-indicator": "qTC",
  "recall-indicator": "qTk",
  "payment-purpose": "qTJ",
  "payment-purpose-label": "qTs",
  "repeat-infobox": "qTF qTI header-section",
  "additional-infobox": "qTg qTI header-section",
  "repeat-transfer-action": "qTD",
  "financing-not-compliant-disclaimer": "qTV"
};
