export default {
  "sidebar-header": "qLD",
  "sidebar-header__top": "qLV",
  "sidebar-header__body": "qLL",
  "sidebar-header__content": "qLT",
  "sidebar-header__date": "qLz",
  "infobox": "qLa",
  "infobox--link": "qLH",
  "ghost": "qLO",
  "fail": "qLm",
  "r-transaction-disclaimer": "qLp",
  "hold-disclaimer": "qLx",
  "transaction-link": "qLw body-2",
  "icon-link": "qLN"
};
